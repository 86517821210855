<template>
  <div>
    <v-expand-transition>
      <OrderRowFilters v-show="showFilters" @clearFilterEvent="onClearFilterEvent" @applyFilterEvent="onApplyFilterEvent"></OrderRowFilters>
    </v-expand-transition>
    <v-expand-transition>
      <OrderFilters v-show="showFilters" @clearFilterEvent="onClearFilterEvent" @applyFilterEvent="onApplyFilterEvent"></OrderFilters>
    </v-expand-transition>
      <v-data-table 
        :headers="headers" 
        :items="orderRowContent" 
        :search="search"
        :loading="!orderRowContent"
        :loading-text="$t('loading')"
        sort-by="date" class="elevation-1"
        item-key="id"
        :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right' }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title v-t="'ordersContent'"></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details></v-text-field>
            <v-spacer></v-spacer>  
            <v-btn color="success" dark class="mb-2 mr-2" @click="apiOrdersContentListExport()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
            <v-btn color="info" dark class="mb-2 mr-2" @click="showFilters = !showFilters">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        
      <template v-slot:item.orderCode="{ item }">
        <div style="display:flex; flex-direction: row;">
          <span>{{ defaultTenant? item.orderCode.substr(-5) : item.orderCode }}</span>
        </div>
      </template>
        <template v-slot:item.isLfu="{ item }">
          <BooleanIcon :booleanValue="item.isLfu"/>
        </template>
        <template v-slot:item.isGift="{ item }">
          <BooleanIcon :booleanValue="item.isGift"/>
        </template>
        <template v-slot:item.customerId="{ item }" style="max-width: 60px">
          <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="bold" style="cursor:pointer;" @click="openCustomer(item.customerId)">
                  {{ getCustomerName(item.customerId) }}
                </span>
              </template>
              <span>{{ getCustomerCode(item.customerId) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item.itemCode="{ item }" style="max-width: 60px">
          <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="bold" style="cursor:pointer;">
                  {{ item.itemCode }}
                </span>
              </template>
              <span>{{ item.itemDescription }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item.agentId="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ getAgentShortName(item.agentId) }}
                </span>
              </template>
              <span v-html="getAgentsNames(item)"></span>
            </v-tooltip>
        </template>
        <template v-slot:item.orderDate="{ item }">
          <td>
            <p class="my-0 py-0" style="word-break: keep-all"><v-icon class="mx-2">mdi-check</v-icon>{{ item.orderDate | formatDate }}</p>
            <p class="my-0 py-0" style="word-break: keep-all"><v-icon class="mx-2">mdi-truck</v-icon>{{ item.estimatedDeliveryDate | formatDate }}</p>
          </td>
        </template>
        <template v-slot:item.documentCurRowOriginalPrice="{ item }">
          {{ item.documentCurRowOriginalPrice | tenantCurrency }}
        </template>
        <template v-slot:item.rowTotal="{ item }">
          {{ item.rowTotal | tenantCurrency }}
        </template>
      </v-data-table>
  </div>
</template>
  
  <script>
    import { get, sync, call } from "vuex-pathify";
    import BooleanIcon from '../../components/app/BooleanIcon';
    import NoDataImg from '../../components/NoDataImg.vue';
    import OrderFilters from '../../components/filters/OrderFilters.vue';
    import OrderRowFilters from '../../components/filters/OrderRowFilters.vue';
    export default {
      components: {
        NoDataImg,
        BooleanIcon,
        OrderFilters,
        OrderRowFilters,
      },
      data: () => ({
        showFilters: false,
        search: '',
      }),
      computed: {
        orderRowContent: get("orders/orderRowContent"),
        //customers: get("customers/allCustomers"),
        bulkCustomers: get("customers/bulkCustomers"),
        agents: get("agents/agents"),
        getAgentShortName: get('agents/getAgentShortName'),
        authUsers: get("authUsers/authUsers"),
        defaultTenant: get("tenantConfiguration/default"),
        headers(){
          return [
            { text: this.$t('agent'), value: 'agentId' },
            { text: this.$t('type'), value: 'orderType' },
            { text: this.$t('order'), value: 'orderCode' },
            { text: this.$t('orderDate'), value: 'orderDate' },
            { text: this.$t('customer'), value: 'customerId' },
            { text: this.$t('item'), value: 'itemCode' },
            { text: this.$t('qty'), value: 'quantity' },
            { text: this.$t('price'), value: 'documentCurRowOriginalPrice' },
            { text: this.$t('disc') + ' %', value: 'rowDiscountPercentage' },
            { text: this.$t('gift'), value: 'isGift' },
            { text: this.$t('lfu'), value: 'isLfu' },
            { text: this.$t('rowTotal'), value: 'rowTotal' },
            { text: this.$t('actions'), value: 'actions', sortable: false, align:"center" },
          ]
        }
      },
      watch: {},
      created() {
        this.initialize()
      },
      methods: {
        initialize() {
          if(!this.orderRowContent.length) this.showFilters = true;
          this.initOrderStatusCollection();
          // this.initOrderRowContentCollection(true);
          this.initBulkCustomersCollection();
        },
        onClearFilterEvent() {
          this.showFilters = false;
          this.initOrderRowContentFilters();
          this.initOrderRowContentCollection(true);
        },
        onApplyFilterEvent() {
          this.initOrderRowContentCollection(true);
        },
        getCustomerName: function (customerId) {
          return (this.bulkCustomers.find(x => x.id === customerId) || {}).businessName || "-";
        },
        getCustomerCode: function(customerId) {
          let customer = this.bulkCustomers.find(x => x.id === customerId);
          return `${(customer || {}).code || ``} - ${(customer || {}).businessName || ``}`;
        },
        openCustomer: function(customerId) {
          this.$router.push('customers/' + customerId)
        },
        getAgentsNames: function (item) {
          if (item.agentId === item.supervisorAgentId) {
            const agentName = ((this.agents.find(x => x.id === item.agentId) || {}).name || "-----");
            return `${agentName}`
          } else {
            const agentName = ((this.agents.find(x => x.id === item.agentId) || {}).name || "-----");
            const supervisorAgentName = ((this.agents.find(x => x.id === item.supervisorAgentId) || {}).name || "");
            return `${agentName}${supervisorAgentName? ` <br /> (${supervisorAgentName})` : ``}`;
          }
        },
        getAuthUser: function (uuid) {
          const authUser = this.authUsers.find(x => x.uuid === uuid);
          if (!!authUser) {
            return `${authUser.givenName} ${authUser.familyName}`;
          } else {
            return "-";
          }
        },
        ...call("customers", [
          "initBulkCustomersCollection"
        ]),
        ...call("orders/*"),
        ...call("utils/*"),
      },
    }
  </script>
